import React from "react";
import { Link, graphql } from 'gatsby';
import Layout from "../components/shared/Layout";
import Breadcrumbs from "../components/shared/Breadcrumbs";
import "../assets/styles/pages/sitemap.scss";

import SEO from "../components/SEO/SEO";

const HtmlSitemap = ({ data }) => {
  const Categories = data.allWpCategory.edges.map(edge =>
    <li key={edge.node.id} className='category'>
      <Link to={edge.node.link.replace('category/', '')} dangerouslySetInnerHTML={{ __html: edge.node.name }} />
    </li>);
  const Posts = data.allWpPost.edges.map(edge =>
    <li key={edge.node.id} className='post'>
      <Link to={edge.node.uri} dangerouslySetInnerHTML={{ __html: edge.node.title }} />
    </li>);
  const Pages = data.allWpPage.edges.map(edge =>
    <li key={edge.node.id} className='page'>
      <Link to={edge.node.link} dangerouslySetInnerHTML={{ __html: edge.node.title }} />
    </li>);
  return (
    <Layout>
      <SEO title={"HTML Sitemap"} description={"Please check full list of pages on " + process.env.SITE_URL}/>
      <Breadcrumbs pageTitle={"Sitemap"} pageUrl={"/sitemap/"} breadcrumbs={[{"text" : "Home", "url" : "/"}, {"text" : "Sitemap", "url" : "/sitemap/"}]}/>
      <div className={"wrapper"}>
        <h1>HTML sitemap</h1>
        <ul className={"sitemap-items-ul"}>
          {Pages}
          {Categories}
          {Posts}
        </ul>
      </div>
    </Layout>

  )
};

export default HtmlSitemap;

export const pageQuery = graphql`
  query all {
    allWpCategory(filter: {slug: {ne: "uncategorized"}}) {
      edges {
        node {
          id
          name
          link
        }
      }
    }
    allWpPost(
        sort: { fields: [date] }
      ) {
      edges {
        node {
          title
          id
          uri
          
        }
      }
    }
    allWpPage(
        sort: { fields: [date] }
      ) {
      edges {
        node {
          title
          id
          slug
          link
          wpParent {
            node {
              slug
            }
          }
        }
      }
    }
  }  
`;
